<template>
  <Box >
  
    <ErrorRow :error="error" /> 
    <SuccessRow :message="message" /> 
    
    <Row>
      <Column :width="4" />
      <Column :width="7">
        <Box v-if="isLoading">
          <LoadingRow  :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
        </Box>
        <Box v-else :showBorder="true">
          
          <ValueRow >{{ MC.Company.Select.Message.value() }}</ValueRow>
          <TitleRow >{{ MC.Company.Select.Title.value() }}</TitleRow>
          
          <Row v-if="!isLoading">
            <Column :width="15" :showBorder="true">
              <Value v-for="(company, index) in companys" 
                     :key="company.id()" 
                     :class="Strings.isEven(index) ? 'rowEven clickable' : 'rowOdd clickable'"
                     v-on:click="selectAction(company)">
                {{ company.name() }}
              </Value>
            </Column>
          </Row>
        </Box>
        
      </Column>
      <Column :width="4" />
    </Row> 
    <SpacerRow />
        
  </Box>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

//import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

export default {
  name: "company-select",
  components: {
    Box, Row, Column,
    SpacerRow, TitleRow, LoadingRow, ValueRow,
    Value,
    ErrorRow, SuccessRow, 
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      companys: [],
      isLoading: true,
      loadingMessage: null,
      
      showSessionTimeoutDialog: false,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
      const session = this.domain.session();
      const user = session.user();
      const employees = user.employees();
      const companysForUser = employees.companys().find();
      this.companys = companysForUser.sortByName();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    selectAction: function(company) {
      if (!company) {
        return;
      }
      if (StringUtils.isEmpty(company.id())) {
        return;
      }
      this.AuthStorage_updateCompany(company);
      var employees = company.employees();
      var employee = employees.forUser(this.domain.session().user());
      this.domain.session().with(company);
      this.domain.session().with(employee);
      this.domain.session().companyName = company.name();
      
      var cookieString = this.$cookie.get("readychek.ca");
      var cookie = JSON.parse(cookieString);
      cookie['Company'] = company.id();
      cookieString = JSON.stringify(cookie);
      window.localStorage.readyChek = cookieString;
      this.$cookie.set("readychek.ca", cookieString, 365);

      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.HOME });
    }
  },
}
</script>